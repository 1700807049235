@import "../../variables.scss";

.Home {
    position: relative;
    overflow: hidden;
    font-size: .7em;

    @include breakpoint(mediumWidth) {
        font-size: 1em;
    }

    .content {
        position: relative;
    }

    h1 {
        font-size: 2.4em;
        font-weight: 100;
        margin-bottom: 0;
        text-align: center;

        @include breakpoint(largeWidth) {
            font-size: 3em;
            text-align: unset;
        }
    }

    p {
        font-size: 1.5em;
        line-height: 1.5em;
    }

    .description {
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 4rem;
        height: calc(100vh - 80px);
        max-height: 1000px;
        text-align: center;

        @include breakpoint(largeWidth) {
            margin-left: 4rem;
            margin-bottom: 0;
            max-width: 450px;
            text-align: left;
        }

        span,
        p {
            background-color: $super-light-orange;
            transition: background-color .5s;
            width: fit-content;

            @include breakpoint(landingBreakPoint) {
                background-color: hsla(0, 0%, 0%, 0);
            }
        }

        p {
            margin: 15px auto 10px auto;
            padding-right: 15px;
            font-size: 1.1em;
            // transform: translateY(10px);
            // opacity: 0;
            // animation: landingText .5s .5s forwards;

            @include breakpoint(largeWidth) {
                margin-left: 0;
                margin-right: 0;
            }
        }

        h1 {
            font-size: 3em;
            // transform: translateY(10px);
            // opacity: 0;
            // animation: landingText .5s .4s forwards;

            @include breakpoint(largeWidth) {
                font-size: 3.5em
            }
        }

        .cta {
            display: block;
            width: fit-content;
            margin: 25px auto 0 auto;
            text-decoration: none;
            color: white;
            background-color: $brown;
            padding: 20px 30px;
            border-radius: 30px;
            font-size: 1em;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.21);
            // opacity: 0;
            // transform: translateY(10px);
            // animation: landingText .5s .6s forwards;

            @include breakpoint(largeWidth) {
                margin-left: 0;
                margin-right: 0;
            }

            transition: background-color .3s;
        }

        .cta:hover{
            background-color: $dark-orange;
            transition: background-color .3s;
        }
    }

    .landing-image {
        z-index: 2;
        position: absolute;
        top: calc(100vh - 380px);
        right: -50px;
        width: 600px;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 0;
        animation: landingImageFadeIn .5s .75s forwards;

        img {
            width: 100%;
        }

        .sparks {
            position: absolute;
            //top: -60px;
            left: 0;
        }

        @include breakpoint(extraSmallWidth) {
            top: calc(100vh - 380px);
        }

        // @include breakpoint(mediumWidth) {
        //     display: none;
        // }

        @include breakpoint(largeWidth) {
            display: unset;
            right: -50px;
            top: calc(100vh - 550px);
            width: min(100%, 1000px);
        }

        @include breakpoint(giantWidth) {
            top: 450px;
        }
    }

    .brown-bar-landing {
        z-index: 2;
        position: absolute;
        top: calc(100vh - 40px);
        right: 0;
        height: 100px;
        width: 100%;
        background-color: $brown;
    }

    .details {
        z-index: 3;
        position: relative;
        margin-top: 40px;
        padding: 80px 2rem 240px 2rem;
        color: white;
        background-color: $brown;
        width: calc(100% - 4rem);

        @include breakpoint(largeWidth) {
            .split {
                display: flex;
                flex-direction: row;
                padding: 0 2rem 0 2rem;
                margin-bottom: 100px;
                width: calc(100% - 4rem);
                min-height: calc(100vh);
            }
        }

        h1 {
            font-size: 4em;
            text-align: center;
            margin-bottom: 80px;

            @include breakpoint(mediumWidth) {
                margin-bottom: 0;
            }
        }

        .keep-scrolling {
            visibility: hidden;
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 100px;
            font-size: 1.25em;
            display: flex;
            justify-content: center;
            opacity: 1;
            transition: opacity .25s;

            @include breakpoint(largeWidth) {
                visibility: visible;
            }

            &.zero-opacity {
                opacity: 0;
                transition: opacity .25s;
            }

            img {
                width: 30px;
                animation: nudgeDown 2s ease-in-out infinite;
            }

            @keyframes nudgeDown {
                0% {
                    transform: translateY(-4px);
                }

                50% {
                    transform: translateY(1px);
                }

                100% {
                    transform: translateY(-4px);
                }
            }
        }


        .text {
            margin: auto;
            margin-bottom: 60px;
            padding-bottom: 40px;

            @include breakpoint(largeWidth) {
                margin-left: 30px;
                width: calc(55% - 15px);
                padding-bottom: unset;
                margin-bottom: auto;
            }


            h2 {
                text-align: center;
                font-size: 3em;
                font-weight: 100;

                @include breakpoint(largeWidth) {
                    text-align: left;
                }

                span {
                    position: relative;

                    span {
                        z-index: 2;
                        padding-right: 30px;
                    }

                    img {
                        position: absolute;
                        top: -35%;
                        left: -40%;
                        width: 125%;
                        z-index: 1;

                        @include breakpoint(mediumWidth) {
                            top: -20%;
                        }
                    }

                    .circle {
                        opacity: 0.5;
                    }
                }
            }

            p {
                font-size: 1.75em;
                text-align: center;

                @include breakpoint(mediumWidth) {
                    text-align: left;
                }
            }
        }


        .scroll-image-container {
            @include breakpoint(mediumWidth) {
                padding-top: 150px;
                min-height: 100vh;
            }

            @include breakpoint(largeWidth) {
                padding-top: 0;
                min-height: unset;
                margin-right: 30px;
                width: calc(45% - 15px);
            }

            margin-top: auto;
            margin-bottom: auto;
            position: relative;

            img {
                width: 100%;
                display: none;

                @include breakpoint(mediumWidth) {
                    display: unset;
                }
            }

            .hand {
                position: absolute;
                top: 150px;
                width: 100%;
                left: 0px;
                display: none;

                @include breakpoint(mediumWidth) {
                    display: unset;
                }


                @include breakpoint(largeWidth) {
                    top: 0;
                }
            }

            .second {
                position: absolute;
                left: 0px;
                width: 100%;
                top: 150px;
                display: none;

                @include breakpoint(mediumWidth) {
                    display: unset;
                }

                @include breakpoint(largeWidth) {
                    top: 0;
                }
            }

            .third-image-container-mobile {
                display: flex;

                @include breakpoint(mediumWidth) {
                    display: none;
                }
            }

            .third-image-container {
                width: 100%;
                position: relative;
                display: none;

                @include breakpoint(mediumWidth) {
                    display: flex;
                }

                @include breakpoint(largeWidth) {
                    padding-top: 0;
                    min-height: unset;
                }

                .smile-face {
                    z-index: 3;
                    width: 60%;
                    margin: auto;
                }

                .smile-bright {
                    color: #CF7E33
                }

                .smile-dark {
                    color: #A59927
                }

                #mouth-frown {
                    transform: rotate(180deg);
                    transform-origin: 50% 71%;
                }

                .happy-explode-container {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    width: 100%;
                    display: flex;

                    .happy-explode {
                        width: 60%;
                        margin: auto;
                    }
                }
            }

            .invisible {
                visibility: hidden !important;
            }
        }

        .svg {
            display: flex;
            justify-content: center;

            @include breakpoint(largeWidth) {
                margin-left: 10px;
                width: calc(40% - 10px);
            }

            img {
                width: 80%;
                max-width: 500px;

            }
        }
    }

    .lonely {
        z-index: 3;
        position: relative;
        margin-top: 40px;
        padding: 80px 2rem;
        width: calc(100% - 4rem);

        @include breakpoint(largeWidth) {
            padding: 80px 4rem;
            width: calc(100% - 8rem);

            div {
                width: 65%;
            }
        }

        h1 {
            font-size: 4.25em;
        }

        #cigarette-with-smoke {
            display: none;

            @include breakpoint(largeWidth) {
                display: unset;
                position: absolute;
                top: 275px;
                right: 0;
            }
        }

        .cigarettes {
            position: absolute;
            right: 0;
        }

        .smoke {
            position: absolute;
            top: -820px;
            right: 0;
            opacity: .5;
        }

        a {
            color: $dark-orange;
            transition: color .3s;
        }

        a:hover {
            color: $mid-light-orange;
            transition: color .3s;
        }
    }

    .background {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 1100px;
        overflow: hidden;
        opacity: 0;
        animation: fadeInBackground 1.5s .5s forwards;

        rect {
            opacity: 0.5;
        }
    }

    .bg-overlay {
        mix-blend-mode: hue;
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $light-orange;
        opacity: 1;
    }

    .bg-hue {
        mix-blend-mode: color-dodge;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #FFF;
        opacity: .5;
    }
}