$super-light-orange: #FFF5EC;
$light-orange: #FFEAD6;
$mid-light-orange: #F4C083;
$mid-orange: #CF7E33;
$dark-orange: #A56427;
$brown: #36250C;
$mid-grey: #858585;
$light-grey: #E2E1E1;

@mixin breakpoint($point) {
    @if $point ==giantWidth {
        @media (min-width: 2100px) {
            @content;
        }
    }

    @else if $point ==extraLargeWidth {
        @media (min-width: 1600px) {
            @content;
        }
    }

    @else if $point == landingBreakPoint {
        @media (min-width: 1400px) { @content; }
    }

    @else if $point ==largeWidth {
        @media (min-width: 900px) {
            @content;
        }
    }

    @else if $point ==mediumWidth {
        @media (min-width: 768px) {
            @content;
        }
    }

    @else if $point ==smallWidth {
        @media (min-width: 600px) {
            @content;
        }
    }

    @else if $point ==extraSmallWidth {
        @media (min-width: 376px) {
            @content;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes fadeInBackground {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}

@keyframes landingImageFadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(-60px);
    }
}

@keyframes landingText {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(-70px);

        
    }
}

@include breakpoint(landingBreakPoint) {
    @keyframes landingText {
        0% {
            opacity: 0;
            transform: translateY(10px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

@keyframes flicker1 {
    0% {
        opacity: 0;
    }
    49% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0.5;
    }
}

@keyframes flicker2 {
    0% {
        opacity: 0.5;
    }
    49% {
        opacity: 0.5;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}