@import "../../variables.scss";

.Navigation {
    z-index: 2;
    position: absolute;
    display: flex;
    margin: 40px 2rem 0 2rem;
    width: calc(100% - 4rem);
    max-width: calc(2100px - 8rem);
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    animation: fadeIn .5s forwards;

    @include breakpoint(smallWidth) {
        margin: 40px 4rem 0 4rem;
        width: calc(100% - 8rem);
    }

    a {
        text-decoration: none;
    }

    .logo{
        display: flex;
    }

    .logo img {
        width: 30px;
        margin: auto;
        margin-right: 7.5px;
    }

    .Normal {
        display: flex;
        justify-content: space-evenly;

        .link {
            mix-blend-mode: exclusion;
            justify-content: space-evenly;
            text-align: center;
            text-decoration: none;
            color: black;
            font-size: 1.3rem;
            margin-left: 2rem;
            position: relative;
            transition: color .25s;

            @include breakpoint(extraLargeWidth) {
                font-size: 1.5rem;
            }

            .link-underline {
                border-bottom: 5px solid $dark-orange;
                border-radius: 10px;
                position: absolute;
                width: 5px;
                bottom: -10px;
                left: calc(50% - 3px);
                opacity: 0;
                transition: bottom .25s, opacity .25s;
            }
        }

        .link:hover {
            color: $dark-orange;
            transition: color .25s;
        }

        .link-active {
            .link-underline {
                opacity: 1;
                bottom: -5px;
                transition: bottom .25s, opacity .25s;
            }
        }
    }

    .Dropdown {
        .button {
            width: 15%;
            margin-top: 5%;
            margin-left: 77.5%;

            @include breakpoint(mediumWidth) {
                width: 20%;
                margin-top: 10%;
                margin-left: 70%;
            }
        }

        .content {
            z-index: 3;
            list-style-type: none;
            position: absolute;
            transform: translateX(-100%);
            left: 0;
            top: 0;
            padding: 0;
            margin: 0;
            height: 100vh;
            width: 100vw;

            .item {
                height: 20%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: white;

                .dropdown-link {
                    text-decoration: none;
                    color: black;
                    font-size: 1.5em;
                }
            }

            .break {
                height: 21%;
                width: 100%;
                background-color: white;
                border: none;
                margin-top: -1%;
            }

        }
    }

    .slide_in {
        animation: slide-in .25s ease-in-out forwards;
    }

    .slide_out {
        animation: slide-out .25s ease-in-out backwards;
        ;
    }

    @for $i from 1 through 5 {
        .slide_in:nth-child(#{$i}) {
            animation-delay: .025s*$i;
        }

        .slide_out:nth-child(#{$i}) {
            animation-delay: .025s*$i;
        }
    }

    @keyframes slide-in {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(100%);
        }
    }

    @keyframes slide-out {
        0% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(200%);
        }
    }
}