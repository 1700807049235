@import "../../variables";

.footer{
    padding: 0 4rem 50px 4rem;
    text-align: center;

    @include breakpoint(largeWidth) {
        text-align: left;
    }
}

