@import './variables.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Verdana', Helvetica, sans-serif;
  max-width: 2100px;
  background-color: $super-light-orange;
  @include breakpoint(extraLargeWidth){
    margin: auto;
  }
}


/* All pages */
#root>div {
  width: 100%;
  opacity: 0;
  animation: fadeIn .5s forwards;
}

.hide {
  display: none;
}

.unico {
  font-family: 'Unica One', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.logo {
  text-align: center;
  font-size: 1.9em;
  color: $dark-orange;
}

.blend {
  mix-blend-mode: exclusion;
}