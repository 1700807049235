@import "../../variables.scss";

.About {
    padding-top: 120px;
    overflow: hidden;
    min-height: calc(100vh - 200px);

    div {
        padding: 0 2rem;

        @include breakpoint(smallWidth){
            padding: 0 4rem
        }
    }

    h1 {
        font-size: 3em;
        transform: translateY(10px);
        opacity: 0;
        animation: landingText .5s .4s forwards;
        text-align: center;

        @include breakpoint(largeWidth) {
            text-align: left;
        }
    }

    h2 {
        font-size: 2.5em;
        transform: translateY(10px);
        opacity: 0;
        animation: landingText .5s .5s forwards;
    }

    p {
        font-size: 1.5em;
        line-height: 1.5em;
        transform: translateY(10px);
        opacity: 0;
        animation: landingText .5s .75s forwards;
    }

    a {
        color: $dark-orange;
        transition: color .3s;
    }

    a:hover {
        color: $mid-light-orange;
        transition: color .3s;
    }
}