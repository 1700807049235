@import "../../variables.scss";

.Demo {
    padding: 120px 0;
    overflow: hidden;
    min-height: calc(100vh - 200px);

    >div {
        margin: auto;
        border-radius: 50px;
        background: white;
        padding: 10px 50px 50px 50px;
        width: 200px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.10);
        transform: translateY(10px);
        opacity: 0;
        animation: landingText .5s .4s forwards;

        @include breakpoint(mediumWidth) {
            padding: 20px 100px 100px 100px;
            width: 500px;
        }
    }

    h1 {
        font-size: 3em;
        margin-bottom: 30px;
        text-align: center;

        @include breakpoint(largeWidth) {
            text-align: left;
        }
    }

    h2 {
        font-size: 2.5em;
        // transform: translateY(10px);
        // opacity: 0;
        // animation: landingText .5s .5s forwards;
    }

    p {
        font-size: 1.5em;
        line-height: 1.5em;
        // transform: translateY(10px);
        // opacity: 0;
        // animation: landingText .5s .75s forwards;
    }

    i {
        display: block;
        margin-bottom: 30px;
    }

    a {
        color: $dark-orange;
        transition: color .3s;
    }

    a:hover {
        color: $mid-light-orange;
        transition: color .3s;
    }

    .field {
        margin: 20px 0;
    }

    label {
        display: none;
    }

    input,
    textarea {
        display: block;
        padding: 15px 20px;
        border-radius: 40px;
        border: 1px solid $light-grey;
        width: 160px;
        font-family: inherit;
        font-size: .85em;

        @include breakpoint(mediumWidth) {
            padding: 20px 20px;
            width: 450px;
        }
    }

    input:focus,
    textarea:focus {
        outline: 2px solid $mid-orange;
    }

    textarea {
        height: 150px;
        resize: none;
    }

    .submit {
        cursor: pointer;
        border: none;
        padding: 15px 20px;
        border-radius: 20px;
        width: 200px;
        background: $mid-orange;
        color: white;
        transition: opacity .1s;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.21);
    }
}